header {
  font-size: 4em;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto 0; /* This will center the h1 vertically */
}

.hand {
  display: inline-block;
  transition: transform 0.2s;
}
.hand:hover {
  animation: wave 0.6s infinite;
}
@keyframes wave {
  0% { transform: rotate(0deg); }
  20% { transform: rotate(-10deg); }
  40% { transform: rotate(12deg); }
  60% { transform: rotate(-10deg); }
  80% { transform: rotate(8deg); }
  100% { transform: rotate(0deg); }
}