.footer-links {
  padding: 2em 0;
  font-size: 0.9em;
  color: #888;
  text-align: center;
}

.footer-links a {
  text-decoration: none;
  color: inherit;
  margin: 0 5px;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #555;
}

.footer-links div {
  margin-bottom: 5px;
}