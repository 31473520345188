* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  min-height: 100svh;
  margin: 0;
  padding: 0;
}

#root {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(
    to bottom,
    #add8e6,
    #ffffff
  ); /* Soft blue to white gradient */
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}